import { fetchWithToken } from 'utils/fetch';
import keysToCamel from 'utils/keysToCamel';
import { mapListings } from 'utils/mapping';
import { urlEncodeBody } from 'utils/requests';

export async function getDashboardData(
  filters: DashboardFilters
): Promise<{ listings: Listing[]; filters: any }> {
  const response = await fetchWithToken('/dashboard', { query: urlEncodeBody(filters).toString() });

  const data = await response.json();
  // const mockedListings = data.listings.map(item => ({
  //   ...item,
  //   transaction_details: {
  //     ...item.transaction_details
  //     // commissions: 2500,
  //     // expenses: 6000,
  //     // offer_price: 250000,
  //     // offer_date: 1611136372,
  //     // effective_date: 1619870376000,
  //     // contract_price: 250000,
  //     // financials: 200000,
  //     // closing_date: 1619870376000,
  //     // post_closing_escrows: 10000
  //   }
  // }));

  return {
    listings: mapListings(data.listings),
    filters: keysToCamel(data.filters)
  };
}

export async function createListing(data: CreateListingFormValues): Promise<void> {
  const response = await fetchWithToken('/listings', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });

  await response.json();
}
