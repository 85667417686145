import React from 'react';
import dayjs from 'dayjs';
import pluralize from 'pluralize';

import { formatCurrency } from 'utils/rifmFormatters';
import AccordionTableItem from 'components/AccordionTableItem';
import ListingPhotoAndAddress from './ListingPhotoAndAddress';
import { getAddressAndCity } from 'utils/listing';
interface SaleItemProps {
  data: Listing;
}

const getTimeDiffInDays = (date) => {
  if (!date) return;
  return Math.abs(dayjs(dayjs()).diff(date, 'day'));
};

const SaleItem: React.FC<SaleItemProps> = ({ data }) => {
  const getListedDate = () => {
    const timeDiffInDays = getTimeDiffInDays(data.listingDate);
    if (timeDiffInDays === 0) return 'Today';
    return `${timeDiffInDays} ${pluralize('day', timeDiffInDays)}`;
  };

  return (
    <AccordionTableItem
      fields={[
        {
          key: 'address',
          label: 'Address',
          value: (
            <ListingPhotoAndAddress
              src={data.images?.[0]}
              address={getAddressAndCity(data)}
            />
          )
        },
        {
          key: 'type',
          label: 'Type',
          value: data.listingTypeHr || '-'
        },
        {
          key: 'price',
          label: 'Listing Price',
          value: formatCurrency(data.currentPrice)
        },
        {
          key: 'date',
          label: 'Listed',
          value: data.listingDate ? getListedDate() : '-'
        },
        {
          key: 'expires',
          label: 'Expires',
          value: data.offMarketDate ? getTimeDiffInDays(data.offMarketDate) : '-'
        },
        {
          key: 'offers',
          label: 'Offers',
          value: data.offersCount || 0
        },
        {
          key: 'commissions',
          label: 'Commissions',
          value: data.commissions ? formatCurrency(data.commissions) : '-'
        },
        {
          key: 'expenses',
          label: 'Expenses',
          value: data.expenses ? formatCurrency(data.expenses) : '-'
        }
      ]}
    />
  );
};

export default SaleItem;
