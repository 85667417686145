import React from 'react';
import dayjs from 'dayjs';

import { formatCurrency } from 'utils/rifmFormatters';
import AccordionTableItem from 'components/AccordionTableItem';
import ListingPhotoAndAddress from './ListingPhotoAndAddress';
import { getAddressAndCity } from 'utils/listing';
interface ClosedItemProps {
  data: Listing;
}

const ClosedItem: React.FC<ClosedItemProps> = ({ data }) => {
  return (
    <AccordionTableItem
      fields={[
        {
          key: 'address',
          label: 'Address',
          value: (
            <ListingPhotoAndAddress
              src={data.images?.[0]}
              address={getAddressAndCity(data)}
            />
          )
        },
        {
          key: 'type',
          label: 'Type',
          value: data.listingTypeHr || '-'
        },
        {
          key: 'closingDate',
          label: 'Closing Date',
          value: data.closingDate ? dayjs(data.closingDate).format('MM/DD/YYYY') : '-'
        },
        {
          key: 'contract_price',
          label: 'Contract Price',
          value: data.contractPrice ? formatCurrency(data.contractPrice) : '-'
        },
        {
          key: 'earnestMoney1',
          label: 'Earnest Money',
          value: data.earnestMoney ? formatCurrency(data.earnestMoney) : '-'
        },
        {
          key: 'financials',
          label: 'Financials',
          value: data.financials ? formatCurrency(data.financials) : '-'
        },
        {
          key: 'post_closing_escrows',
          label: 'Post-closing escrows',
          value: data.postClosingEscrows ? formatCurrency(data.postClosingEscrows) : '-'
        },
        {
          key: 'commisions',
          label: 'Commisions',
          value: data.commissions ? formatCurrency(data.commissions) : '-'
        }
      ]}
    />
  );
};

export default ClosedItem;
