import React from 'react';

import { styled } from 'styles';
import Accordion from 'components/Accordion';
import Button from 'components/Button';
import AgentInfo from './AgentInfo';
interface ListingItemProps {
  data: Listing;
  renderLabel: () => JSX.Element;
  onSeeMoreClick: () => void;
}

const ListingItem: React.FC<ListingItemProps> = ({ data, renderLabel, onSeeMoreClick }) => {
  return (
    <StyledAccordion
      label={renderLabel()}>
      <AgentInfo
        name={data.listingAgent.name}
        emails={data.listingAgent.email}
        officePhone={data.listingAgent.officePhone}
        mobilePhone={data.listingAgent.mobilePhone}
        offers={data.latest3Offers}
      />
      <Button onClick={onSeeMoreClick}>See more</Button>
    </StyledAccordion>
  );
};

export default ListingItem;

const StyledAccordion = styled(Accordion)`
  width: 100%;
  .label {
    width: 100%;
  }
  .counter {
    display: none;
  }
  .accordion-content {
    margin: 0 0 16px 24px;
  }
  .menu-btn {
    .icon {
      fill: ${props => props.theme.colors.graphite};
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    .table-header-info {
      &__item {
        &.address {
          flex: 2.5;
          padding: 0 16px 0 0;
          overflow: hidden;
          * > span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        &.actions {
          flex: 0.5;
        }
      }
    }
  }
`;
