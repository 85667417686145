import React from 'react';
import { styled } from 'styles';
import clsx from 'clsx';
import dayjs from 'dayjs';

import useLayout from 'hooks/useLayout';
import Icon from 'components/Icon';

interface AgentInfoProps {
  className?: string;
  name?: string;
  emails?: string[];
  officePhone?: string;
  mobilePhone?: string;
  offers?: Array<Offer>;
}

const AgentInfo: React.FC<AgentInfoProps> = ({ name, emails, officePhone, mobilePhone, offers, className }) => {
  const layout = useLayout();

  return (
    <StyledAgentInfo className={clsx('agent-info', className)}>
      <div className="agent-info__, &__info-item">
        <div className="agent-info__info-header">Contact Person</div>
        <div className="agent-info__item name">{name}</div>
        {emails?.map((email, idx) => (
          <div key={idx} className="agent-info__item">
            <Icon name="email" />
            <a href={`mailto:${email}`} className="link">
              {email}
            </a>
          </div>
        ))}
        {officePhone && (
          <div className="agent-info__item">
            <Icon name="phone" />
            <a href={`tel:${officePhone}`} className="link">
              {officePhone}
            </a>
          </div>
        )}
        {mobilePhone && (
          <div className="agent-info__item">
            <Icon name="smartphone" />
            <a href={`tel:${mobilePhone}`} className="link">
              {mobilePhone}
            </a>
          </div>
        )}
      </div>
      {layout !== 'mobile' && offers?.length !== 0 && (
        <div className="agent-info__container">
          <div className="agent-info__info-header">Recent Offers</div>
          {offers?.map(offer => (
            <div className="agent-info__cols" key={offer.id}>
              <div className="agent-info__item">{offer.offerAmount}</div>
              <div className="agent-info__item">{dayjs.unix(offer.created).format('MM/DD/YYYY')}</div>
              <div className="agent-info__item">{offer.name || offer.data.buyer1Name}</div>
              <div className="agent-info__item email">{offer.email || offer.data.buyer1Email}</div>
              <div className="agent-info__item">{offer.phone || offer.data.buyer1Phone}</div>
            </div>
          ))}
        </div>
      )}
    </StyledAgentInfo >
  );
};

export default AgentInfo;

const StyledAgentInfo = styled.div`
  width: 100%;
  margin: 12px 0 0;
  display: flex;
  .agent-info {
    &__container {
      margin: 0 0 0 50px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    &__cols {
      display: flex;
      & > div {
        flex: 1;
        min-width: 120px;
        &.email {
          flex: 2;
        }
      }
    }
    &__info-block, &__item {
      margin: 0 12px 0 0;
    }
    &__info-header {
      font-size: 12px;
      line-height: 16px;
      color: ${props => props.theme.colors.grayDark};
      margin: 0 12px 12px 0;
    }
    &__item {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      color: ${props => props.theme.colors.mineShaft};
      .icon {
        margin: 0 5px 0 0;
      }
      &.name {
        font-weight: 600;
      }
      &.price,
      &.data {
        color: black;
      }
      .link {
        text-decoration: none;
        color: unset;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    .agent-info {
      &__container {
        padding: 12px 0;
      }
      &__, &__info-item {
        margin: 0 32px 0 0;
      }
    }
  }
`;
