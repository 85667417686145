import React from 'react';

import { formatCurrency } from 'utils/rifmFormatters';
import AccordionTableItem from 'components/AccordionTableItem';
import ListingPhotoAndAddress from './ListingPhotoAndAddress';
import dayjs from 'dayjs';
import { getAddressAndCity } from 'utils/listing';
interface UnderContractItemProps {
  data: Listing;
}

const UnderContractItem: React.FC<UnderContractItemProps> = ({ data }) => {
  return (
    <AccordionTableItem
      fields={[
        {
          key: 'address',
          label: 'Address',
          value: (
            <ListingPhotoAndAddress
              src={data.images?.[0]}
              address={getAddressAndCity(data)}
            />
          )
        },
        {
          key: 'type',
          label: 'Type',
          value: data.listingTypeHr || '-'
        },
        {
          key: 'effective_date',
          label: 'Effective Date',
          value: data.effectiveDate ? dayjs(data.effectiveDate).format('MM/DD/YYYY') : '-'
        },
        {
          key: 'contract_price',
          label: 'Contract Price',
          value: data.contractPrice ? formatCurrency(data.contractPrice) : '-'
        },
        {
          key: 'earnest_money',
          label: 'Earnest Money',
          value: data.earnestMoney ? formatCurrency(data.earnestMoney) : '-'
        },
        {
          key: 'closing_cost_credits',
          label: 'Closing Cost Credits',
          value: data.closingCostCredit ? formatCurrency(data.closingCostCredit) : '-'
        },
        {
          key: 'financials',
          label: 'Financials',
          value: data.financials ? formatCurrency(data.financials) : '-'
        },
        {
          key: 'closing_date',
          label: 'Closing Date',
          value: data.closingDate ? dayjs(data.closingDate).format('MM/DD/YYYY') : '-'
        },
        {
          key: 'commisions',
          label: 'Commisions',
          value: data.commissions ? formatCurrency(data.commissions) : '-'
        }
      ]}
    />
  );
};

export default UnderContractItem;
