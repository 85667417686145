import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Checkbox, Input, InputPlaces, SideMenu, SideMenuProps } from 'components';
import { styled } from 'styles';
import ReactInputMask from 'react-input-mask';
import { REGEXP } from 'consts';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { createListing } from './api';

const optionalStringWithSpace = (str?: string) => (str ? `${str} ` : '');

interface CreateNewListingProps extends SideMenuProps {
  onSuccess: () => any;
}

const CreateNewListing: React.FC<CreateNewListingProps> = ({ onClose, onSuccess, ...props }) => {
  const { register, control, handleSubmit, setValue, formState, errors } = useForm();

  const submit = async (form: CreateListingFormValues) => {
    try {
      const data = {
        ...form,
        orders: Object.entries(form.orders)
          .filter(([, value]) => value)
          .map(([key]) => key)
      };
      await createListing(data as any);
      notify('Listing created');
      onSuccess();
      onClose?.();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const handleAddressChange = place => {
    if (!place) return;
    const streetNumber = place.address_components?.find(item =>
      item.types.includes('street_number')
    )?.long_name;
    const route = place.address_components?.find(item => item.types.includes('route'))?.long_name;
    const city = place.address_components?.find(item => item.types.includes('locality'))?.long_name;
    const state = place.address_components?.find(item =>
      item.types.includes('administrative_area_level_1')
    )?.short_name;
    const postalCode = place.address_components?.find(item => item.types.includes('postal_code'))
      ?.short_name;
    if (route) setValue('address1', `${optionalStringWithSpace(streetNumber)}${route}`);
    if (city) setValue('city', city);
    if (state) setValue('state', state);
    if (postalCode) setValue('postalCode', postalCode);
  };

  const checkKeyDown = e => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <StyledSideMenu title="Create New Listing" onClose={onClose} {...props}>
      <hr />
      <form onKeyDown={checkKeyDown} onSubmit={handleSubmit(submit)}>
        <h4 className="title">Property Information</h4>
        <div className="process-form__input-block">
          <Controller
            control={control}
            name="address"
            defaultValue=""
            rules={{
              required: 'Required',
              maxLength: {
                value: 100,
                message: `Address can not exceed 100 characters`
              },
              pattern: {
                value: REGEXP.ADDRESS,
                message: 'Invalid Address'
              }
            }}
            render={({ onChange, ...controllerProps }) => (
              <InputPlaces
                label={
                  <>
                    Enter address<span className="red-asterisk">*</span>
                  </>
                }
                placeholder="Enter address"
                error={errors.address?.message}
                autoComplete="off"
                onChange={onChange}
                onPlaceChange={(query, place) => {
                  handleAddressChange(place);
                  onChange(query);
                }}
                {...controllerProps}
              />
            )}
          />
        </div>
        <div className="input-group">
          <Input
            ref={register({
              required: 'Required',
              maxLength: {
                value: 100,
                message: `Address can not exceed 100 characters`
              },
              pattern: {
                value: REGEXP.ADDRESS,
                message: 'Invalid Address'
              }
            })}
            name="address1"
            error={errors.address1?.message}
            label="Address line 1"
            placeholder="Enter address 1"
          />
          <Input
            ref={register}
            name="address2"
            label="Address line 2"
            placeholder="Enter address 2"
          />
        </div>
        <div className="input-group">
          <Input
            ref={register({ required: 'Required' })}
            name="state"
            error={errors.state?.message}
            label="State"
            placeholder="Enter State"
          />
          <Input
            ref={register({ required: 'Required' })}
            name="city"
            error={errors.city?.message}
            label="City"
            placeholder="Enter City"
          />
        </div>
        <div className="input-group">
          <Input
            ref={register({
              required: 'Required',
              pattern: {
                value: REGEXP.ZIP_CODE,
                message: 'Invalid ZIP code'
              }
            })}
            name="postalCode"
            error={errors.postalCode?.message}
            label="ZIP Code"
            placeholder="Enter ZIP Code"
          />
          <div />
        </div>
        <hr />
        <h4 className="title">Seller Information</h4>
        <div className="input-group">
          <Input
            ref={register({
              pattern: {
                value: REGEXP.NAME,
                message: 'Invalid name'
              }
            })}
            name="seller.name"
            error={errors.seller?.name?.message}
            label="Name"
            placeholder="Enter Name"
          />
          <Input
            ref={register({
              pattern: {
                value: REGEXP.EMAIL,
                message: 'Invalid email format'
              }
            })}
            name="seller.email"
            error={errors.seller?.email?.message}
            label="Email"
            placeholder="Enter Email"
          />
        </div>
        <div className="input-group">
          <Controller
            control={control}
            name="seller.phone"
            rules={{
              validate: value => !value.includes('_') || `Invalid phone`
            }}
            defaultValue=""
            render={controllerProps => (
              <Input
                className="deadline-form__input"
                as={ReactInputMask}
                mask="+1 (999) 999-9999"
                type="tel"
                error={errors.seller?.phone?.message}
                label="Phone Number"
                placeholder="Enter Phone Number"
                {...controllerProps}
              />
            )}
          />
          <div />
        </div>
        <hr />
        <h4 className="title">Orders</h4>
        <Checkbox ref={register} name="orders.professional_photos" label="Professional photos" />
        <Checkbox ref={register} name="orders.lockbox" label="Lockbox" />
        <Checkbox ref={register} name="orders.floor_plan" label="Floor plan" />
        <Checkbox ref={register} name="orders.3d_tour" label="3D Tour" />
        <div className="form-buttons">
          <Button secondary type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={formState.isSubmitting}>
            Create
          </Button>
        </div>
      </form>
    </StyledSideMenu>
  );
};

export default CreateNewListing;

const StyledSideMenu = styled(SideMenu)`
  .side-menu {
    width: 700px;
  }

  h4.title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  form {
    display: flex;
    flex-direction: column;

    .input {
      width: 100%;
      margin-bottom: 16px;

      .red-asterisk {
        color: ${props => props.theme.colors.red};
      }
    }
    .input-group {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      grid-gap: 24px;
    }
    .checkbox {
      margin-bottom: 12px;
    }
    .form-buttons {
      display: flex;
      justify-content: flex-end;

      .button:nth-of-type(1) {
        margin-right: 8px;
      }
    }
  }
`;
