import React, { useContext, useMemo, useState } from 'react';
import { styled } from 'styles';
import useLayout from 'hooks/useLayout';

import MainLayout from 'components/MainLayout';
import { Button, Icon, Input, Tabs } from 'components';
import PopMenu from 'components/PopMenu';
import TableHeader from 'components/TableHeader';
import { WorkspaceContext } from 'workspace';
import DashboardContext, { DashboardContextProvider } from './context';
import ListingItem from './ListingItem';
import ClosedItem from './ClosedItem';
import SaleItem from './SaleItem';
import PurchaseItem from './PurchaseItem';
import UnderContractItem from './UnderContractItem';
import CreateNewListing from './CreateNewListing';

const tableHeaderFields: { [key: TabKey]: { value: string; sort?: DashboardTableSortKeys; }[]; } = {
  sales: [
    { value: 'Address', sort: 'address1' },
    { value: 'Type', sort: 'listing_type_hr' },
    { value: 'Listing Price', sort: 'current_price' },
    { value: 'Listed', sort: 'listing_date' },
    { value: 'Expires', sort: '' },
    { value: 'Offers', sort: 'offersCount' },
    { value: 'Commisions', sort: '' },
    { value: 'Expenses', sort: '' }
  ],
  purchases: [
    { value: 'Address', sort: 'address1' },
    { value: 'Type', sort: 'listing_type_hr' },
    { value: 'Listed', sort: 'listing_date' },
    { value: 'Offer Price', sort: '' },
    { value: 'Offer Date', sort: '' },
    { value: 'Earnest Money', sort: 'earnest_money_1' },
    { value: 'Financials', sort: '' },
    { value: 'Closing Date', sort: '' },
    { value: 'Actions', sort: '' }
  ],
  underContract: [
    { value: 'Address', sort: 'address1' },
    { value: 'Type', sort: 'listing_type_hr' },
    { value: 'Effective Date', sort: '' },
    { value: 'Contract Price', sort: '' },
    { value: 'Earnest Money', sort: 'earnest_money_1' },
    { value: 'Closing Cost Credits', sort: 'closing_cost_credit' },
    { value: 'Financials', sort: '' },
    { value: 'Closing Date', sort: '' },
    { value: 'Commisions', sort: '' }
  ],
  closedListings: [
    { value: 'Address', sort: 'address1' },
    { value: 'Type', sort: 'listing_type_hr' },
    { value: 'Closing Date', sort: '' },
    { value: 'Contract Price', sort: '' },
    { value: 'Earnest Money', sort: '' },
    { value: 'Financials', sort: '' },
    { value: 'Post-closing escrows', sort: '' },
    { value: 'Commissions', sort: '' }
  ]
};

type TabKey = 'sales' | 'purchases' | 'underContract' | 'closedListings';

type Tab = { key: TabKey; text: React.ReactNode; };

export type ListingPopupData = {
  target?: EventTarget | HTMLElement;
  data: Listing;
};

const DashboardPage = () => {
  const { openWorkspace } = useContext(WorkspaceContext);
  const { listings, filters, setFilters, handleSort, getListings } = useContext(DashboardContext);
  const [activeTab, setActiveTab] = useState<TabKey>('sales');
  const [createNewListingIsOpen, setCreateNewListingIsOpen] = useState(false);
  const [listingPopupData, setListingPopupData] = useState<ListingPopupData | null>();
  const layout = useLayout();
  const downloadLink = useMemo(() => listingPopupData?.data?.latest3Offers?.[0]?.link || listingPopupData?.data?.latest3Offers?.[0]?.data?.officialOffer?.[0]?.url, [listingPopupData]);

  const { sales, purchases, underContract, closedListings } = useMemo(() => {
    const sales: Listing[] = [];
    const purchases: Listing[] = [];
    const underContract: Listing[] = [];
    const closedListings: Listing[] = [];
    if (listings) {
      listings.forEach(item => {
        switch (item.status) {
          case 'Active':
          case 'Pending':
          case 'Canceled':
          case 'Expired':
          case 'Not Listed':
            sales.push(item);
            break;
          case 'Offer Placed':
            purchases.push(item);
            break;
          case 'Under Contract':
            underContract.push(item);
            break;
          case 'Closed':
            closedListings.push(item);
            break;
          default:
            break;
        }
      });
    }
    return { sales, purchases, underContract, closedListings };
  }, [listings]);

  const tabs: Tab[] = [
    {
      key: 'sales',
      text: (
        <span>
          My Sales
          <span className="counter">{sales.length}</span>
        </span>
      )
    },
    {
      key: 'purchases',
      text: (
        <span>
          My Purchases
          <span className="counter">{purchases.length}</span>
        </span>
      )
    },
    {
      key: 'underContract',
      text: (
        <span>
          Under Contract
          <span className="counter">{underContract.length}</span>
        </span>
      )
    },
    {
      key: 'closedListings',
      text: (
        <span>
          Closed Listings
          <span className="counter">{closedListings.length}</span>
        </span>
      )
    }
  ];

  const handleOpenWorkspace = () => {
    if (!listingPopupData?.data) return;
    openWorkspace(listingPopupData?.data);
    setListingPopupData(null);
  };

  const getTabContent = (key: TabKey) => {
    if (!listings) return null;
    switch (key) {
      case 'sales':
        return (
          <>
            {sales.length === 0 && <div className="no-data">no data</div>}
            {sales.map(listing => (
              <ListingItem
                key={listing.id}
                data={listing}
                renderLabel={() => <SaleItem data={listing} />}
                onSeeMoreClick={() => openWorkspace(listing)}
              />
            ))}
          </>
        );
      case 'purchases':
        return (
          <>
            {purchases.length === 0 && <div className="no-data">no data</div>}
            {purchases.map(listing => (
              <ListingItem
                key={listing.id}
                data={listing}
                renderLabel={() => <PurchaseItem data={listing} onClickMenu={setListingPopupData} />}
                onSeeMoreClick={() => openWorkspace(listing)}
              />
            ))}
          </>
        );
      case 'underContract':
        return (
          <>
            {underContract.length === 0 && <div className="no-data">no data</div>}
            {underContract.map(listing => (
              <ListingItem
                key={listing.id}
                data={listing}
                renderLabel={() => <UnderContractItem data={listing} />}
                onSeeMoreClick={() => openWorkspace(listing)}
              />
            ))}
          </>
        );
      case 'closedListings':
        return (
          <>
            {closedListings.length === 0 && <div className="no-data">no data</div>}
            {closedListings.map(listing => (
              <ListingItem
                key={listing.id}
                data={listing}
                renderLabel={() => <ClosedItem data={listing} />}
                onSeeMoreClick={() => openWorkspace(listing)}
              />
            ))}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <StyledMainLayout>
      <StyledDashboardPage className="dahboard-page">
        <div className="table-sticky">
          <div className="dahboard-page__header">
            <h4 className="dahboard-page__title">Dashboard</h4>
            {layout === 'desktop' && (
              <Button
                className="dahboard-page__create-btn"
                onClick={() => setCreateNewListingIsOpen(true)}>
                Create new listing
              </Button>
            )}
            {layout === 'tablet' && (
              <div className="dahboard-page__search-filters">
                <Input
                  className="search"
                  placeholder="Search"
                  icon="search"
                  iconPosition="left"
                  onChange={e => setFilters({ search: e.target.value })}
                />
                <Button
                  className="dahboard-page__create-btn"
                  onClick={() => setCreateNewListingIsOpen(true)}>
                  <Icon name="plus" />
                </Button>
              </div>
            )}
            {layout === 'mobile' && (
              <Button
                className="dahboard-page__create-btn"
                onClick={() => setCreateNewListingIsOpen(true)}>
                <Icon name="plus" />
              </Button>
            )}
          </div>
          {layout === 'mobile' && (
            <div className="dahboard-page__search-filters">
              <Input
                className="search"
                placeholder="Search"
                icon="search"
                iconPosition="left"
                onChange={e => setFilters({ search: e.target.value })}
              />
            </div>
          )}
          <div className="dahboard-page__filters scrollbar-custom">
            <div className="dahboard-page__tabs">
              <StyledTabs
                activeTab={activeTab}
                onSelect={(_e, value) => setActiveTab(value.key)}
                items={tabs}
                large
              />
            </div>
            {layout === 'desktop' && (
              <Input
                className="search"
                placeholder="Search"
                icon="search"
                iconPosition="left"
                onChange={e => setFilters({ search: e.target.value })}
              />
            )}
          </div>
          {(layout === 'desktop' || layout === 'tablet') && (
            <TableHeader
              accordionHeader
              fields={tableHeaderFields[activeTab]}
              onSort={handleSort}
              filters={{ sort: filters.sort, order: filters.order }}
            />
          )}
        </div>
        {getTabContent(activeTab)}
      </StyledDashboardPage>
      <CreateNewListing
        open={createNewListingIsOpen}
        onClose={() => setCreateNewListingIsOpen(false)}
        onSuccess={() => getListings(filters)}
      />
      {/* <ListingInfo
        onClose={() => openWorkspace(undefined)}
        open={openListingInfoMenu}
        data={listingInfoMenuData}
      /> */}
      <StyledPopMenu
        open={Boolean(listingPopupData?.target)}
        onOutsideClick={() => setListingPopupData(null)}
        placement="bottom-end"
        target={listingPopupData?.target}
        unmountChildren
        strategy="fixed"
        className="listing-menu">
        <ul className="listing-options">
          <li className="listing-options__item">
            <a href={listingPopupData?.data.url} target="_blank">
              <Icon name="arrow" size={12} /> View listing
            </a>
          </li>
          <li className="listing-options__item" onClick={handleOpenWorkspace}>
            <Icon name="gear" /> Go to workspace
          </li>
          {downloadLink && (
            <li className="listing-options__item" onClick={() => null}>
              <Icon name="download" size={12} />
              <a href={downloadLink} target="_blank" download>
                Download offer
            </a>
            </li>
          )}
        </ul>
      </StyledPopMenu>
    </StyledMainLayout>
  );
};

export default props => (
  <DashboardContextProvider>
    <DashboardPage {...props} />
  </DashboardContextProvider>
);

const StyledDashboardPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: auto;
  height: 100%;
  margin-right: -40px;
  padding: 0 20px 20px 0;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-right: -20px;
  }

  .dahboard-page {
    &__header {
      display: flex;
      justify-content: space-between;
      margin: 0 0 28px;
      width: 100%;
    }
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: black;
      margin: 0;
    }
    &__filters {
      display: flex;
      width: 100%;
      margin: 0 0 32px;
      align-items: center;
      .search {
        margin-left: auto;
        width: 100%;
        max-width: 300px;
      }
    }
    &__create-btn {
      min-width: 100px;
    }
    &__manage-btn {
      margin-left: auto;
    }
  }
  .search {
    .input-wrapper {
      .input-component {
        height: 32px;
      }
    }
  }

  .table-sticky {
    width: 100%;
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 1000;
  }

  .table-header {
    &__item {
      &.address {
        flex: 2.5;
        padding: 0 16px 0 0;
      }
      &.actions {
        flex: 0.5;
        .value {
          display: none;
        }
      }
    }
  }
  .no-data {
    color: ${props => props.theme.colors.darkGray};
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .dahboard-page {
      &__create-btn {
        min-width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__search-filters {
        display: flex;
        .search {
          width: 300px;
          margin: 0 16px 0 0;
        }
      }
      &__header {
        margin: 0 0 16px;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .dahboard-page {
      &__header {
        margin: 0 0 12px;
      }
      &__filters {
        overflow-x: auto;
        margin: 0;
      }
      &__tabs {
        padding-bottom: 8px;
      }
      &__search-filters {
        display: flex;
        width: 100%;
        margin: 0 0 16px;
        .search {
          width: 100%;
          margin: 0;
          .input-wrapper {
            .input-component {
              height: 40px;
            }
          }
        }
      }
    }
  }
`;

const StyledTabs = styled(Tabs)`
  padding: 0;
  flex-wrap: nowrap;
  .counter {
    margin: 0 0 0 4px;
    color: ${props => props.theme.colors.grayDark};
  }
  .tab-item {
    flex-shrink: 0;
    &.active {
      .counter {
        color: ${props => props.theme.colors.red};
      }
    }
  }
`;

const StyledPopMenu = styled(PopMenu)`
  .listing-options {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    &__item {
      padding: 8px 18px;
      font-size: 14px;
      line-height: 18px;
      cursor: pointer;
      a {
        color: inherit;
        text-decoration: none;
      }
      .icon {
        margin-right: 10px;
        fill: ${props => props.theme.colors.red};
      }
    }
  }
`;

const StyledMainLayout = styled(MainLayout)`
.content {
  /* padding: 0; */
  padding-bottom: 0;
  height: calc(100vh - 80px);
}

@media(max-width: ${props => props.theme.breakpoints.sm}) {
  height: calc(100vh - 52px);
}
`;
