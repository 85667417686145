import React from 'react';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import { Button, Icon } from 'components';
import AccordionTableItem from 'components/AccordionTableItem';
import ListingPhotoAndAddress from './ListingPhotoAndAddress';
import { ListingPopupData } from './';
import { getAddressAndCity } from 'utils/listing';
import { formatCurrency } from 'utils/rifmFormatters';
interface PurchaseItemProps {
  data: Listing;
  onClickMenu: (value: ListingPopupData) => void;
}

const getTimeDiffInDays = date => {
  if (!date) return;
  return dayjs(dayjs()).diff(date, 'day');
};

const PurchaseItem: React.FC<PurchaseItemProps> = ({ data, onClickMenu }) => {
  const getListedDate = () => {
    const timeDiffInDays = getTimeDiffInDays(data.listingDate);
    if (timeDiffInDays === 0) return 'Today';
    return `${timeDiffInDays} ${pluralize('day', timeDiffInDays)}`;
  };

  return (
    <AccordionTableItem
      fields={[
        {
          key: 'address',
          label: 'Address',
          value: (
            <ListingPhotoAndAddress
              src={data.images?.[0]}
              address={getAddressAndCity(data)}
            />
          )
        },
        {
          key: 'type',
          label: 'Type',
          value: data.listingTypeHr || '-'
        },
        {
          key: 'date',
          label: 'Listed',
          value: data.listingDate ? getListedDate() : '-'
        },
        {
          key: 'offer_price',
          label: 'Offer Price',
          value: data.lastOfferAmount ? formatCurrency(data.lastOfferAmount) : '-'
        },
        {
          key: 'offer_date',
          label: 'Offer Date',
          value: data.lastOfferDate ? dayjs.unix(data.lastOfferDate).format('MM/DD/YYYY') : '-'
        },
        {
          key: 'earnest_money',
          label: 'Earnest Money',
          value: data.earnestMoney ? formatCurrency(data.earnestMoney) : '-'
        },
        {
          key: 'financials',
          label: 'Financials',
          value: data.financials ? formatCurrency(data.financials) : '-'
        },
        {
          key: 'closing_date',
          label: 'Closing Date',
          value: data.closingDate ? dayjs(data.closingDate).format('MM/DD/YYYY') : '-'
        },
        {
          key: 'actions',
          label: '',
          value: (
            <Button
              simple
              onClick={e => {
                e.stopPropagation();
                onClickMenu({ target: e.target, data }
                );
              }}
              className="menu-btn">
              <Icon name="menu-dots" />
            </Button >
          )
        }
      ]}
    />
  );
};

export default PurchaseItem;
