import React from 'react';
import { styled } from 'styles';
import useLayout from 'hooks/useLayout';
import clsx from 'clsx';
import Icon from '../Icon';
import Button from '../Button';

interface AccordionTableItemProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  hasLink?: boolean;
  hasMenu?: boolean;
  url?: string;
  fields: {
    key: string;
    label?: string;
    value: React.ReactNode;
  }[];
}

const AccordionTableItem: React.FC<AccordionTableItemProps> = ({
  fields,
  url,
  hasLink,
  hasMenu,
  children
}) => {
  const layout = useLayout();

  return (
    <StyledAccordionTableItem className="table-header">
      <div className="table-header-info">
        {(layout === 'desktop' || layout === 'tablet') &&
          fields.map(({ key, value }) => (
            <span className={clsx('table-header-info__item', key)} key={key}>
              {value}
            </span>
          ))}

        {layout === 'mobile' &&
          fields.map(({ key, label, value }) => (
            <span className={clsx('table-header-info__item', key)} key={key}>
              <span className="label">{label}</span> {value}
            </span>
          ))}
        {hasLink && (
          <span className="table-header-info__item table-header-info__link">
            {url && (
              <a href={url} className="table-header-link" target="_blank" rel="noreferrer">
                {(layout === 'desktop' || layout === 'mobile') && 'View page'}
                {layout === 'tablet' && 'View'}
                <Icon name="arrow" />
              </a>
            )}
          </span>
        )}
        {hasMenu && (
          <span className="table-header-menu">
            <Button simple>
              <Icon name="menu-dots" />
            </Button>
          </span>
        )}
        {children}
      </div>
    </StyledAccordionTableItem>
  );
};

const StyledAccordionTableItem = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid ${props => props.theme.colors.seashell};
  font-weight: normal;
  position: relative;
  &:last-of-type {
    border-bottom: none;
  }
  .table-header-info {
    display: flex;
    flex: 1;
    &__item {
      flex: 1;
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      display: flex;
      &.title {
        .label {
          display: none;
        }
      }
    }
    &__item-img {
      width: 24px;
      height: 24px;
      margin: 0 12px 0 0;
      flex-shrink: 0;
      border-radius: ${props => props.theme.misc.borderRadius};
    }
    .table-header-link {
      color: ${props => props.theme.colors.red};
      fill: ${props => props.theme.colors.red};
      text-decoration: none;
      display: flex;
      align-items: center;
      .icon {
        margin-left: 5px;
        transform: none;
      }
    }
    .table-header-menu {
      width: 16px;
      .icon {
        transform: none;
        fill: ${props => props.theme.colors.graphite};
        &.icon-menu-dots {
          margin: 0;
        }
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .table-header-info {
      flex-direction: column;
      .label {
        width: 100px;
        margin: 0 10px 0 0;
        color: ${props => props.theme.colors.darkGray};
      }
      &__item {
        margin: 16px 0 0;
        &:first-of-type {
          margin: 0;
        }
      }
      .table-header-link {
        .icon {
          margin-top: 0;
        }
      }
      .table-header-menu {
        position: absolute;
        top: 4px;
        right: 0;
      }
    }
  }
`;
export default AccordionTableItem;
