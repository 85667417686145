import React, { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import usePersistedState from 'hooks/usePersistedState';
import { getDashboardData } from './api';

interface DashboardContextValues {
  listings?: Listing[];
  getListings: (filters: any) => Promise<void>;
  filters: DashboardFilters;
  setFilters: (value: DashboardFilters) => void;
  handleSort: (value?: DashboardTableSortKeys) => void;
}

const DashboardContext = React.createContext({} as DashboardContextValues);

export const DashboardContextProvider = ({ children }) => {
  const [listings, setListings] = usePersistedState<Listing[]>('listings', []);
  const [filters, setFiltersDispatch] = useState<DashboardFilters>({ search: '' });

  const [setFilters] = useDebouncedCallback((values: DashboardFilters) => {
    setFiltersDispatch(v => ({ ...v, ...values }));
  }, 300);

  const handleSort = (key?: DashboardTableSortKeys) => {
    const { sort, order } = filters;
    let sortValues: { sort?: DashboardTableSortKeys; order?: SortDirection; } = {
      sort: undefined,
      order: undefined
    };

    if (sort !== key) {
      sortValues = { sort: key, order: 'desc' };
    } else if (order === 'desc') {
      sortValues = { sort: key, order: 'asc' };
    }

    setFilters({ ...sortValues });
  };

  const getListings = useCallback(async filters => {
    try {
      const { listings } = await getDashboardData(filters);
      setListings(listings);
    } catch (err) {
      notify(err.response || err.message);
      // notify(tryGetFirstError(err.response) || err.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getListings(filters);
  }, [getListings, filters]);

  const values: DashboardContextValues = {
    listings,
    getListings,
    filters,
    setFilters,
    handleSort
  };
  return <DashboardContext.Provider value={values}>{children}</DashboardContext.Provider>;
};

export default DashboardContext;
