import React from 'react';
import { styled } from 'styles';
import clsx from 'clsx';

import Icon from 'components/Icon';

interface TableHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  hasMenu?: boolean;
  accordionHeader?: boolean;
  filters?: {
    sort?: string;
    order?: string;
  };
  onSort?: (value?: any) => void;
  fields: {
    value: string;
    sort?: string;
  }[];
}

const TableHeader: React.FC<TableHeaderProps> = ({
  fields,
  hasMenu,
  accordionHeader,
  filters,
  onSort
}) => {
  return (
    <StyledTableHeader
      className={clsx('table-header', {
        'has-menu': hasMenu,
        'accordion-header': accordionHeader
      })}>
      {fields?.map(({ value, sort }) => {
        const formattedValue = value.toLowerCase().replace(/\s+/g, '-');
        return (
          <span
            className={clsx('table-header__item', formattedValue, filters?.order, {
              sort: Boolean(sort)
            })}
            key={value}
            onClick={() => onSort?.(sort)}>
            <span className="value">{value}</span>
            {sort && filters?.sort === sort && <Icon name="arrow" size={10} />}
          </span>
        );
      })}
    </StyledTableHeader>
  );
};

const StyledTableHeader = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${props => props.theme.colors.seashell};
  padding-bottom: 12px;
  margin-bottom: 16px;
  .table-header {
    &__item {
      font-size: 12px;
      line-height: 16px;
      color: ${props => props.theme.colors.grayDark};
      display: flex;
      flex: 1;
      align-items: center;
      &.sort {
        > * {
          cursor: pointer;
        }
        .value {
          padding-right: 5px;
        }
        .icon-arrow {
          fill: ${props => props.theme.colors.red};
          transform: rotate(0);
        }
      }
      &.desc {
        .icon-arrow {
          transform: rotate(-90deg);
        }
      }
      &.asc {
        .icon-arrow {
          transform: rotate(90deg);
        }
      }
    }
  }
  &.accordion-header {
    padding-left: 25px;
    .table-header {
      &__item {
        &:first-of-type {
          .value {
            margin-left: -25px;
          }
        }
      }
    }
  }
  &.has-menu {
    padding-right: 16px;
  }
`;
export default TableHeader;
